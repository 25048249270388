/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import flagIcon from '../../assets/Images/signin/India.png';
import Select from 'react-select';
import tradly from 'tradly';
import axios from 'axios';
import { Auth_text } from '../../constant/Translation/Auth_text';

const PhoneForm = ({ setNumber, setPassword, setDialCode }) => {
  const [countries, setCountries] = useState(null);

  useEffect(() => {
    axios.get('/api/countries').then((res) => {
      setCountries(res.data.countries);
    });
  }, [0]);

  return (
    <div className=" flex flex-col  justify-center items-center">
      <div className=" w-full md:w-96  h-12 mb-5   bg-transparent  rounded-input   text-white outline-none placeholder-white flex justify-center items-center">
        {countries && (
          <PhoneInput
            onlyCountries={countries?.map((country) => {
              return country.code2.toLowerCase();
            })}
            country={countries && countries[0]?.code2.toLowerCase()}
            onChange={(mobile, country, e) => {
              setNumber(mobile);
              setDialCode(country.dialCode);
            }}
            name={Auth_text.login.mobile_number}
          />
        )}
      </div>
      <input
        type="password"
        className=" w-full md:w-96  h-12  bg-transparent  border border-white rounded-input p-3 text-white outline-none placeholder-white"
        placeholder={Auth_text.login.password}
        onChange={(e) => setPassword(e.target.value)}
      />
    </div>
  );
};

export default PhoneForm;
