/* eslint-disable react/prop-types */
import { useRouter } from 'next/dist/client/router';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SignInPageLayout from '../themes/common_layouts/SignInPageLayout';
import {
  authSelector,
  setUserLoginByIntegration,
} from '../store/feature/authSlice';
import { useDispatch } from 'react-redux';
import { setGeneralConfig } from '../store/feature/configsSlice';
import axios from 'axios';
import { TYPE_CONSTANT } from '../constant/Web_constant';
import {
  set_domain_name,
  encryptDataWithAES,
  decryptDataWithAES,
  set_host_name,
} from '../constant/sdk_set';
import { Common_ssr_functions } from '../constant/SSR_functions';
import {
  set_root_configs,
  ssr_configs_set,
} from '../constant/Root_configs_set';
import {
  all_integrations_data,
  metadata,
  structuredData,
} from '../constant/Metadata';
import { seo_text } from '../constant/static_text';
import SEO_Head from '../constant/SEO_Head';
import { ssr_translation_functions } from '../constant/Translation/SSR_translation';
import { Auth_text } from '../constant/Translation/Auth_text';
import { set_root_translation } from '../constant/Translation/translation_set';
import { Menubar_text } from '../constant/Translation/Heading';
import { General_text } from '../constant/Translation/General';
import { Notification_text } from '../constant/Translation/Notification';
import Loading from '../components/Shared/Loading/Loading';
import CustomLoading from '../components/Shared/Loading/CustomLoading';
import AuthPagesLayout from '../themes/blank/AuthPagesLayout';

const SignIn = ({ configs_list_data, client_translations }) => {
  const router = useRouter();

  const { payload, sig } = router.query || {};

  const dispatch = useDispatch();
  const { login } = useSelector(authSelector);

  //
  const [is_sso_enabled, setIsSSOEnabled] = useState(false);

  useEffect(() => {
    if (is_sso_enabled && sig === undefined && payload === undefined) {
      axios
        .get('/api/sso')
        .then((response) => {
          router.push(response?.data?.redirect_url);
        })
        .catch((error) => {
          //
        });
    }

    if (is_sso_enabled && sig && payload) {
      axios
        .post('/api/sso/return', { data: { sig, payload } })
        .then((response) => {
          dispatch(setUserLoginByIntegration(response?.data)).then((res) => {
            if (router.query.to) {
              router.push(router.query.to);
            } else {
              router.push('/');
            }
          });
        })
        .catch((error) => {
          console.log(error);
          //
        });
    }
  }, [is_sso_enabled]);
  // Configs decryption
  const [configs_list, setConfigsList] = useState(
    JSON.parse(decryptDataWithAES(configs_list_data))
  );

  useEffect(() => {
    // Decrypt the data when the prop value changes
    const decryptedValue = JSON.parse(decryptDataWithAES(configs_list_data));
    setConfigsList(decryptedValue);

    // checking sso enabaling from integrations
    if (decryptedValue?.general?.sso_enabled) {
      setIsSSOEnabled(true);
    }
  }, [configs_list_data]);

  //
  useEffect(() => {
    if (login) {
      if (router.query.to) {
        router.push(router.query.to);
      } else {
        router.push('/');
      }
    } else {
      dispatch(
        setGeneralConfig({
          general_configs: TYPE_CONSTANT.GENERAL_CONFIGS,
        })
      );
    }
  }, [login, router]);

  // Set Meta Data
  metadata.title = seo_text.sign_in_page_title;
  metadata.meta_description = seo_text.sign_in_page_description;
  metadata.image = configs_list?.seo?.meta_image
    ? configs_list?.seo?.meta_image
    : configs_list?.general?.web_logo;
  all_integrations_data.google_tag_manager =
    configs_list?.integrations?.google_tag_manager;

  all_integrations_data.facebook_pixel =
    configs_list?.integrations?.facebook_pixel;
  structuredData.json_data = {};
  metadata.url = typeof window !== 'undefined' && window.location.href;
  //
  if (typeof window !== 'undefined') {
    set_root_configs({
      is_draft: router?.query?.draft,
      all_configs: configs_list,
    });

    // translation set
    set_root_translation({
      translation_values: client_translations,
      group_names: ['more', 'general', 'notification', 'login', 'signup'],
      translation_file: [
        Menubar_text,
        General_text,
        Notification_text,
        Auth_text,
      ],
    });
  }

  // auth page config
  const auth_config_available = router?.query?.draft
    ? configs_list?.draft_styles?.styles?.pages?.auth
    : configs_list?.pages?.auth;

  return is_sso_enabled ? (
    <CustomLoading />
  ) : (
    <>
      <SEO_Head />
      {auth_config_available ? (
        <AuthPagesLayout auth_from_type={'sign_in'} />
      ) : (
        <SignInPageLayout />
      )}
    </>
  );
};

export default SignIn;

// Server Side Function
export async function getServerSideProps({ req, res, query }) {
  var host = set_domain_name(req);
  const auth_key = req.cookies[`${set_host_name(req)}_auth_key`];
  let default_language =
    req.cookies[`${set_host_name(req)}_default_language`] ?? undefined;
  const currency = req.cookies[`${set_host_name(req)}_default_currency`];

  // SSR FUnctions
  const { configs_list } = await Common_ssr_functions({
    host,
    auth_key,
    currency,
    req,
    res,
    query,
  }).then(async (res) => {
    return res;
  });

  // console.log('>>>>>>>>>>>>>>>>>>>',   configs_list);

  // configs set
  ssr_configs_set({ is_draft: query.draft, all_configs: configs_list });

  // client_translations
  const { client_translations } = await ssr_translation_functions({
    host,
    auth_key,
    currency,
    req,
    res,
    query,
    translation_group: ['more', 'general', 'notification', 'login', 'signup'],
    translation_file: [
      Menubar_text,
      General_text,
      Notification_text,
      Auth_text,
    ],
  }).then(async (res) => {
    return res;
  });
  // metadata
  metadata.title = seo_text.sign_in_page_title;
  metadata.meta_description = seo_text.sign_in_page_description;
  metadata.url = req?.headers?.host + req?.url;
  metadata.image = configs_list?.seo?.meta_image
    ? configs_list?.seo?.meta_image
    : configs_list?.general?.web_logo;
  all_integrations_data.google_tag_manager =
    configs_list?.integrations?.google_tag_manager;

  all_integrations_data.facebook_pixel =
    configs_list?.integrations?.facebook_pixel;
  structuredData.json_data = {};

  return {
    props: {
      configs_list_data: encryptDataWithAES(JSON.stringify(configs_list)),
      client_translations,
    },
  };
}
