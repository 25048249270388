import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { configsSelector } from '../../store/feature/configsSlice';
import OnBoardingImage from '../../components/OnBoardingImage/OnBoardingImage';
import SignInForm from '../../components/SigninForm/SignInForm';

const SignInPageLayout = () => {
  const { general_configs } = useSelector(configsSelector);

  return (
    <div className=" grid grid-cols-1  c-md:grid-cols-2  justify-center  items-center ">
      <div className=" hidden w-full min-h-screen  c-md:flex items-center">
        <OnBoardingImage />
      </div>
      <div className="w-full min-h-screen bg-primary">
        <SignInForm general_configs={general_configs} />
      </div>
    </div>
  );
};

export default SignInPageLayout;
