/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import EmailForm from './EmailForm';
import { uuid } from 'uuidv4';
import { useDispatch } from 'react-redux';
import {
  authSelector,
  clearState,
  signIn,
  socialSignIn,
} from '../../store/feature/authSlice';
import PopUp from '../Shared/PopUp/PopUp';
import * as EmailValidator from 'email-validator';
import { useSelector } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import { useRouter } from 'next/dist/client/router';
import PhoneForm from './PhoneForm';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import dynamic from 'next/dynamic';
import Loading from '../Shared/Loading/Loading';
import { Auth_text } from '../../constant/Translation/Auth_text';
import { General_text } from '../../constant/Translation/General';
import { push_data_layer } from '../../constant/GTMDatalayer';
import { getCookie } from 'cookies-next';
import { decryptDataWithAES } from '../../constant/sdk_set';

const SocialLoginForm = dynamic(() => import('./SocialLoginForm'), {
  ssr: false,
});

const SignInForm = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [number, setNumber] = useState(null);
  const [dialcode, setDialCode] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const dispatch = useDispatch();
  const router = useRouter();

  const {
    isSocialLoginFetching,
    isFetching,
    isSuccess,
    errorMessage,
    isError,
  } = useSelector(authSelector);

  const closePopUP = () => {
    dispatch(clearState());
    setShowError(false);
    setError_message('');
  };

  // click signin
  const clickSignIn = (signin_data, type) => {
    if (type === 'google_signin') {
      const uUid = decryptDataWithAES(
        getCookie(`${TYPE_CONSTANT.DOMAIN}_default_uuid`)
      );
      const users = {
        uuid: uUid,
        provider: 'google',
        token: signin_data?.tokenObj?.id_token,
        platform: 'web',
        type: 'customer',
      };

      dispatch(socialSignIn({ prams: users })).then((res) => {
        if (!res.payload.code) {
          push_data_layer({
            event: 'sign_in',
            data: {
              method: 'google',
            },
          });
          if (router.query.to) {
            router.push(router.query.to);
          } else {
            router.push('/');
          }
        }
      });
    } else if (type === 'facebook_signin') {
      const uUid = decryptDataWithAES(
        getCookie(`${TYPE_CONSTANT.DOMAIN}_default_uuid`)
      );

      const users = {
        uuid: uUid,
        provider: 'facebook',
        token: signin_data?.accessToken,
        platform: 'web',
        type: 'customer',
      };

      dispatch(socialSignIn({ prams: users })).then((res) => {
        if (!res.payload.code) {
          push_data_layer({
            event: 'sign_in',
            data: {
              method: 'google',
            },
          });
          if (router.query.to) {
            router.push(router.query.to);
          } else {
            router.push('/');
          }
        }
      });
    } else {
      if (TYPE_CONSTANT?.AUTH_TYPE === 1) {
        if (email === null) {
          setShowError(true);
          setError_message(Auth_text.login.enter_valid_emailid);
          return false;
        }
        if (!EmailValidator.validate(email)) {
          setShowError(true);
          setError_message(Auth_text.login.enter_valid_emailid);
          return false;
        }

        if (password === null) {
          setShowError(true);
          setError_message(
            `${Auth_text.login.password} ${Auth_text.login.required}`
          );
          return false;
        }
        const uUid = decryptDataWithAES(
          getCookie(`${TYPE_CONSTANT.DOMAIN}_default_uuid`)
        );
        const users = {
          user: {
            uuid: uUid,
            email: email,
            password: password,
            type: 'customer',
          },
        };

        dispatch(signIn({ prams: users })).then((res) => {
          if (!res.payload.code) {
            push_data_layer({
              event: 'sign_in',
              data: {
                method: 'email',
                email: email,
              },
            });
            if (router.query.to) {
              router.push(router.query.to);
            } else {
              router.push('/');
            }
          }
        });
      }
      if (TYPE_CONSTANT?.AUTH_TYPE === 3) {
        if (number === null) {
          setShowError(true);
          setError_message(Auth_text.login.enter_valid_phoneno);
          return false;
        }
        if (!isValidPhoneNumber(`+${number}`)) {
          setShowError(true);
          setError_message(Auth_text.login.enter_valid_phoneno);
          return false;
        }

        if (password === null) {
          setShowError(true);
          setError_message(
            `${Auth_text.login.password} ${Auth_text.login.required}`
          );
          return false;
        }
        const uUid = decryptDataWithAES(
          getCookie(`${TYPE_CONSTANT.DOMAIN}_default_uuid`)
        );
        const users = {
          user: {
            uuid: uUid,
            mobile: number.slice(dialcode.length),
            password: password,
            dial_code: dialcode,
            type: 'customer',
          },
        };

        dispatch(signIn({ prams: users })).then((res) => {
          if (!res.payload.code) {
            push_data_layer({
              event: 'sign_in',
              data: {
                method: 'mobile',
                mobile: number.slice(dialcode.length),
                dial_code: dialcode,
              },
            });
            if (router.query.to) {
              router.push(router.query.to);
            } else {
              router.push('/');
            }
          }
        });
      }
    }
  };

  return (
    <div className="w-full    min-h-screen py-10  c-md:py-36 flex items-center justify-center">
      <div className="">
        {(showError || isError) && (
          <OutsideClickHandler
            onOutsideClick={() => {
              (showError || isError) &&
                (setShowError(false),
                setError_message(''),
                dispatch(clearState()));
            }}
          >
            <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
              <div className="">
                <PopUp
                  message={error_message || errorMessage}
                  closePopUP={closePopUP}
                />
              </div>
            </div>
          </OutsideClickHandler>
        )}
        <Loading loading={isSocialLoginFetching} />

        <h2 className=" px-[34px]  md:px-24  text-center text-white text-[30px] md:text-[40px] font-semibold mb-2 c-md:mb-4">
          {TYPE_CONSTANT.GENERAL_CONFIGS?.registration_title}
        </h2>
        <p className=" px-[34px]  md:px-24  text-center text-white text-xl  font-semibold ">
          {Auth_text.login.login_to_your_account}
        </p>
        <div className=" mt-8   md:px-[34px]  ">
          {TYPE_CONSTANT.AUTH_TYPE === 1 && (
            <div>
              <EmailForm setEmail={setEmail} setPassword={setPassword} />
            </div>
          )}
          {TYPE_CONSTANT.AUTH_TYPE === 3 && (
            <div>
              <PhoneForm
                setNumber={setNumber}
                setPassword={setPassword}
                setDialCode={setDialCode}
              />
            </div>
          )}
          <div className=" mt-3 c-md:mt-12 flex flex-col justify-center items-center">
            <button
              className=" mb-8  w-full  xs:w-72 h-12 flex justify-center items-center bg-white rounded-button text-primary font-semibold  text-base gap-4"
              onClick={clickSignIn}
              id={`${TYPE_CONSTANT.DOMAIN}-signin`}
            >
              <span> {Auth_text.login.login}</span>
              {isFetching && (
                <svg
                  className="animate-spin   h-5 w-5 text-primary"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </button>

            <button
              className=" w-full md:w-96 h-6 flex justify-center items-center bg-transparent   text-white  font-medium  text-xl"
              onClick={() =>
                router.push(
                  router.query.to
                    ? `/forgot-password?to=${router.query.to}`
                    : '/forgot-password'
                )
              }
            >
              {Auth_text.login.forgot_your_password}
            </button>
          </div>
          {/* Social Sign In  */}
          {(TYPE_CONSTANT.GENERAL_CONFIGS?.google_sign_in ||
            TYPE_CONSTANT.GENERAL_CONFIGS?.facebook_sign_in ||
            TYPE_CONSTANT.ALL_INTEGRATIONS?.google_login ||
            TYPE_CONSTANT.ALL_INTEGRATIONS?.facebook_sign_in) && (
            <SocialLoginForm clickSignIn={clickSignIn} />
          )}

          {/*  */}
          <div className="mt-10 c-md:mt-20 flex justify-center items-center">
            <Link
              href={
                router.query.to ? `/sign-up?to=${router.query.to}` : '/sign-up'
              }
              passHref
            >
              <button className=" w-full md:w-96 h-6 flex flex-col md:flex-row justify-center items-center bg-transparent   text-white  font-medium text-base  xs:text-xl gap-2">
                {Auth_text.login.dont_have_an_account}
                <span className="font-semibold ">
                  {Auth_text.login.sign_up}{' '}
                </span>
              </button>
            </Link>
          </div>
          <div className=" mt-10 rounded-button flex justify-center items-center">
            <Link href={'/'} passHref>
              <button className=" w-full md:w-96 h-6 flex justify-center items-center bg-transparent   text-white  font-medium text-base  xs:text-xl ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>
                <span className="ltr:ml-2 rtl:mr-2 mt-1">
                  {General_text.general.back_to_home}
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInForm;
