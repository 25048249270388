/* eslint-disable react/prop-types */
import React from 'react';
import { Auth_text } from '../../constant/Translation/Auth_text';

const EmailForm = ({ setEmail, setPassword }) => {
  return (
    <div className=" flex flex-col  justify-center items-center">
      <input
        type="text"
        className=" w-full md:w-[390px]  h-12 mb-5 bg-transparent border  border-white  rounded-input p-3 text-white outline-none placeholder-white     focus:border-white focus:ring-0  "
        placeholder={Auth_text.login.email}
        required
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        className=" w-full md:w-[390px]  h-12  bg-transparent  border border-white rounded-input p-3 text-white outline-none placeholder-white focus:border-white focus:ring-0"
        placeholder={Auth_text.login.password}
        required
        onChange={(e) => setPassword(e.target.value)}
      />
    </div>
  );
};

export default EmailForm;
